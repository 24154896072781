:root {
  --primary-color: rgb(52, 55, 60);
  --secondary-color: #990000;
  --heading-font-family: "Raleway", sans-serif;
  --default-font-family: "Libre Baskerville", serif;
}
.hero {
  padding: 120px 0 40px 0;
}

@media (min-width: 576px) {
  .Home {
    margin: 0 30px;
  }
}

h1 {
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-style: normal;
  font-size: 36px;
  line-height: 40px;
  color: var(--primary-color);
}

p {
  font-family: var(--default-font-family);
  font-weight: 400;
  font-style: italic;
}

.Home .contact-btn {
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-style: normal;
  line-height: 25px;
  border: 1.5px solid var(--secondary-color);
  color: var(--secondary-color);
}

.Home .contact-btn:hover {
  color: #fbf8f5;
  border: 1.5px solid var(--secondary-color);
  background-color: var(--secondary-color);
}

.custom-heading {
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-style: normal;
  color: var(--secondary-color);
  font-size: 18px;
}

img {
  border-radius: 6px;
}
