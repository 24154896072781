@import "tailwindcss";

body {
  margin: 0 30px 40px;
  background-color: #fbf8f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
