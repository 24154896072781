:root {
  --primary-color: rgb(52, 55, 60);
  --secondary-color: #990000;
  --heading-font-family: "Raleway", sans-serif;
  --default-font-family: "Libre Baskerville", serif;
}

.footer-text {
  text-align: center;
  margin-top: 80px;
  font-size: 13px;
  color: var(--primary-color);
  opacity: 0.7;
}

.footer-text a {
  color: var(--secondary-color);
}
