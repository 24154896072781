:root {
  --primary-color: rgb(52, 55, 60);
  --secondary-color: #990000;
  --heading-font-family: "Raleway", sans-serif;
  --default-font-family: "Libre Baskerville", serif;
}

.navigation {
  margin: 0;
  padding: 0;
}

.navigation li {
  display: inline;
  list-style: none;
  line-height: 38px;
}

.navigation ul {
  padding: 0;
}

.navigation a {
  margin-left: 15px;
  text-decoration: none;
  color: var(--primary-color);
  opacity: 0.8;
}

.navigation a:hover,
.email-link:hover {
  color: var(--secondary-color);
}

.custom-heading {
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-style: normal;
  color: var(--secondary-color);
  font-size: 18px;
  margin: 25px 0;
}
