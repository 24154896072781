:root {
  --primary-color: rgb(52, 55, 60);
  --secondary-color: #990000;
  --heading-font-family: "Raleway", sans-serif;
  --default-font-family: "Libre Baskerville", serif;
}

.Work {
  margin: 100px 20px;
}

.Work h1 {
  font-weight: 700;
  font-style: normal;
  font-size: 36px;
  line-height: 40px;
  color: var(--primary-color);
}

.Work h3 {
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-style: normal;
  color: var(--secondary-color);
  font-size: 18px;
  margin: 25px 0;
}

p {
  font-family: var(--default-font-family);
  font-weight: 400;
  font-style: italic;
}

.comment {
  font-size: 12px;
}

.project-image {
  transition: transform 0.3s ease;
}

.project-image:hover {
  transform: scale(1.05);
}
