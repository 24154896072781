:root {
  --primary-color: rgb(52, 55, 60);
  --secondary-color: #990000;
  --heading-font-family: "Raleway", sans-serif;
  --default-font-family: "Libre Baskerville", serif;
}

.Contact {
  margin-top: 100px;
  max-width: 100%;
  margin-bottom: 100px;
}

h1 {
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-style: normal;
  font-size: 36px;
  line-height: 40px;
  color: var(--primary-color);
}

.Contact h3 {
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-style: normal;
  color: var(--secondary-color);
  font-size: 18px;
  margin: 25px 0;
}

.email-link {
  text-decoration: none;
  color: var(--primary-color);
  font-family: var(--heading-font-family);
}

.email-link:hover {
  color: var(--secondary-color);
}

.linkedin-link {
  text-decoration: none;
  color: var(--primary-color);
  font-family: var(--heading-font-family);
}

.linkedin-link:hover {
  color: var(--secondary-color);
}
