:root {
  --primary-color: rgb(52, 55, 60);
  --secondary-color: #990000;
  --heading-font-family: "Raleway", sans-serif;
  --default-font-family: "Libre Baskerville", serif;
}

.About {
  margin: 100px 0;
}

@media (min-width: 576px) {
  .About {
    margin: 100px 30px;
  }
}

.About h2 {
  font-size: 18px;
}

.about-text {
  font-size: 14px;
}

.About .contact-btn {
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-style: normal;
  line-height: 25px;
  border: 1.5px solid var(--secondary-color);
  color: var(--secondary-color);
}

.About .contact-btn:hover {
  color: #fbf8f5;
  border: 1.5px solid var(--secondary-color);
  background-color: var(--secondary-color);
}

.About .text-section {
  width: 50%;
}

.About .image-section {
  width: 50%;
}

.About img {
  max-height: 500px;
}

@media (max-width: 712px) {
  .About .section {
    display: block !important;
  }
  .About .image-section {
    width: 75%;
    margin: 40px auto;
  }

  .About .text-section {
    width: 100%;
  }
}
