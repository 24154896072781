.footer {
  text-align: center;
  margin-top: 100px;
}

.social-links i {
  margin: 0 8px;
  color: var(--primary-color);
  background-color: #f4efea;
  padding: 10px;
  border-radius: 50px;
}

.contact-box {
  display: block;
  background-color: #f4efea;
  padding: 30px 20px;
  border-radius: 8px;
}

@media (min-width: 470px) {
  .contact-box {
    display: flex;
  }
}

@media (min-width: 786px) {
  .contact-box {
    margin: 0 100px 0 100px;
  }
}

.email-link {
  text-decoration: none;
  color: var(--primary-color);
  font-family: var(--heading-font-family);
}

.email-link:hover {
  color: var(--secondary-color);
}

.footer .contact-btn:hover {
  border: 1.5px solid var(--secondary-color);
}

h4 {
  font-family: var(--heading-font-family);
  font-size: 28px;
}

.container .footer .contact-btn {
  font-family: var(--heading-font-family);
  font-weight: 700;
  font-style: normal;
  line-height: 25px;
  border: 1.5px solid var(--secondary-color);
  background-color: var(--secondary-color);
  color: #f4efea;
}

.container .footer .contact-btn:hover {
  color: var(--secondary-color);
  border: 1.5px solid var(--secondary-color);
  background-color: #f4efea;
}

p {
  font-family: var(--default-font-family);
  font-weight: 400;
  font-style: italic;
}

.social-links {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f4efea;
  overflow: hidden;
  position: relative;
  text-decoration: none;
}

.social-icon i {
  font-size: 1.2rem;
  transition: transform 0.3s;
}

.social-icon:hover i {
  animation: icon-move 0.7s ease forwards;
}

@keyframes icon-move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100%);
  }
  51% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
